<template>
  <div class="aboutUs-container">
    <header-nav></header-nav>
    <div class="main">
      <div class="main-header">
        <!-- <div class="header-content">
          <img
            src="@/assets/images/about/aboutus_banner_icon.png"
            alt
            width="99px"
          />
          <p class="title" style="color: #ffffff">关于我们</p>
        </div> -->
      </div>
      <div class="contact_list">
        <p class="title">企业介绍</p>
        <span>CONTACT&nbsp;&nbsp;US&nbsp;&nbsp;COMPANYPROFILE</span>
        <p class="line"></p>
        <!-- <h2 style="margin-top: 20px; font-size: 24px">新巢天诚</h2> -->
      </div>
      <div class="bg_about">
        <div class="about_list">
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;江苏新巢天诚智能技术有限公司、浙江天诚熵智科技有限公司、 四川天诚熵智科技有限公司专注于物联网多形态智能硬件、人工智能产品研发与制造，为不同垂直领域的客户提供整体解决方案服务，深度赋能智慧社区、公租房.人才公寓、智慧公寓、智慧校园、智慧办公、智慧养老、智慧园区、企业办公集宿、产业园等行业应用场景。
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;智能产品：天诚自主研发的多模态计算机人工视觉与生物识别AI物联网锁、AI人脸识别终端、人行通道闸机、国密智慧门禁、会议电子班牌、养老产品、消费产品、AI智能视频分析、车辆通行道闸等；打造整体的全场景智慧通行整体解决方案。
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;软件产品：天诚自主研发的AIoT物联网平台、AI人工智能大模型数据平台、保障性住房数字化运营平台、公租房管理系统、人才公寓管理系统、智慧公寓（长租公寓）管理系统、智慧校园.后勤管理系统、智慧园区宿舍管理系统、智慧养老管理系统、网约房（出租屋）管理系统等软件平台，在软件开发与项目管理有着丰富的经验，领先技术水平，致力于行业软件引领行业发展。
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;目前拥有苏州、杭州、成都、深圳4大研发中心，物联网软硬产品、AI人工智能产品研发人数达到150多人，研发团队有着10多年的物联网、人工智能智能硬件、大数据、云计算机和运维实施工作经验，超过10年以上拥有丰富的项目成功案例策划经验。获得了20余项国家发明专利、120余项软件著作权。 新巢天诚以“质量为本、科技管理、不断创新”为宗旨，以“科技让生活触手可及”为理念，从产品的软硬件研发到解决方案拥有核心技术的高科技公司，为推进AIoT全场景智慧通行的发展做出贡献。
          </p>
        </div>
      </div>

      <div class="contact_list">
        <p class="title">企业文化</p>
        <span>CORPORATE &nbsp;&nbsp;CULTURE</span>
        <p class="line"></p>
        <!-- <h2 style="margin-top: 20px; font-size: 24px">新巢天诚</h2> -->
      </div>
      <div class="idea">
        <div class="ideaContent">
          <div class="ideaFirst">
            <p style="margin-bottom: 10px">企业宗旨</p>
            <p>以人为本，诚信务实，科技创新，回报社会</p>
          </div>
          <div class="ideaFirst ideaFirst2">
            <p style="margin-bottom: 10px">企业理念</p>
            <p>信用第一，用户至上，真诚合作，共创未来</p>
          </div>
          <div class="ideaFirst ideaFirst2">
            <p style="margin-bottom: 10px">员工精神</p>
            <p>尽职尽责，善于沟通，同事和谐，学习创新</p>
          </div>
        </div>
      </div>

      <div class="certificate">
        <!-- <Row type="flex" justify="space-between" class="code-row-bg">
          <Col
            span="8"
            class="certificateContainer"
            v-for="item in qualificationsList"
            :key="item.id"
          >
            <img :src="item.url" alt="" width="300px" height="220px" />
          </Col>
        </Row> -->

        <div
          class="certificateContainer"
          v-for="item in qualificationsList"
          :key="item.id"
        >
          <img :src="item.url" alt="" class="aboutImg" />
        </div>
      </div>

      <div class="dataPage">
        <Page
          :total="totalNum"
          show-elevator
          show-total
          :page-size="pageSize"
          :current="currentPage"
          @on-change="changePage"
          @on-page-size-change="changePageSize"
        />
      </div>
    </div>

    <footer-nav></footer-nav>

    <!-- 定位栏 -->
    <location-column></location-column>
  </div>
</template>

<script>
import headerNav from "@/components/header-nav";
import footerNav from "@/components/footer-nav";
import { getAboutList } from "@/api/newWebsite";
export default {
  name: "about-us",
  components: { headerNav, footerNav },
  metaInfo: {
    title: "新巢天诚-关于我们 智慧通行|人脸识别终端|人行通道闸机|天诚全场景AIoT租住整体解决方案",
    meta: [
      {
        name: "keywords",
        content:
          "天诚NB-IoT智能门锁，CAT.1物联网锁，Wi-Fi智能锁，4G智能门锁，校园智能门锁，保障性住房管理平台，公租房管理系统，人才公寓管理系统，网约房（出租屋管理系统），智慧校园后勤管理系统，智慧园区企业后勤管理系统。",
      },
      {
        name: "description",
        content:
          "江苏新巢天诚智能技术有限公司（简称：天诚）专注于物联网多形态智能硬件的产品研发与制造，为不同垂直领域的客户提供整体解决方案服务，深度赋能公租房.人才公寓、智慧公寓、人才公寓、保障性住房、智慧校园、企业园区宿舍、智慧后勤，网约房（出租屋）等行业应用场景。天诚自主研发的多模态计算机视觉与生物识别物联网锁，采用NB-IoT、CAT.1、Wi-Fi、4G,5G等无线通讯技术。打造整体的智能硬件解决方案。",
      },
    ],
  },
  data() {
    return {
      totalNum: 0,
      pageSize: 12,
      currentPage: 1,
      qualificationsList: [],
    };
  },

  methods: {
    changePage(index) {
      this.currentPage = index;
      this.getAboutList();
    },
    changePageSize(index) {
      this.pageSize = index;
      this.getAboutList();
    },

    getAboutList() {
      getAboutList({
        pageNum: this.currentPage,
        pageSize: this.pageSize,
      })
        .then((res) => {
          if (res.code == 0) {
            this.qualificationsList = res.data.list;
            this.totalNum = res.data.pageTotal;
          } else {
            this.$Message.error(res.message);
          }
        })
        .catch((err) => {
          this.$Message.error("获取数据失败,请稍后再试");
        });
    },
  },
  created() {
    window.scrollTo({
      top: 0,
      behavior: 'auto',
    });
    // this.$store.commit("changeAppointment", true);
    // this.$store.commit("changeLocation", 230);
    this.getAboutList();
  },
  mounted() {
    // this.viedoWidth = this.$refs.viedo_container.offsetWidth.toString();
    // this.viedoHeight = this.$refs.viedo_container.offsetHeight.toString();
    // this.playerOptions.aspectRatio = this.viedoWidth + ":" + this.viedoHeight;
    //    页面尺寸变化,动态复制视频的缩放比例
    // window.onresize = () => {
    //   return (() => {
    //     this.viedoWidth = this.$refs.viedo_container.offsetWidth.toString();
    //     this.viedoHeight = this.$refs.viedo_container.offsetHeight.toString();
    //     this.playerOptions.aspectRatio =
    //       this.viedoWidth + ":" + this.viedoHeight;
    //   })();
    // };
  },
};
</script>

<style scoped>
.aboutUs-container {
  /* min-width: 1366px; */
}
.main {
  width: 100%;
  padding-top: 80px;
}
.main-header {
  width: 100%;
  height: 386px;
  text-align: center;
  background-image: url("../assets/images/zz/guanui_01.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.header-content {
  height: 160px;
  margin: auto;
  padding-top: 120px;
  padding-left: 20px;
}
.title {
  font-size: 40px;
  margin-top: 30px;
  font-weight: bold;
}
.contact_list {
  margin-top: 40px;
  max-width: 1200px;
  margin: auto;
}
.line {
  width: 80px;
  margin: auto;
  border-bottom: 2px solid #d34100;
  margin-top: 10px;
}
.content {
  font-size: 15px;
  padding-top: 20px;
  color: #333333;
  margin: auto;
  text-align: left;
  letter-spacing: 2px;
  line-height: 25px;
}
.viedo {
  width: 1200px;
  height: 520px;
  margin: auto;
  margin-top: 30px;
  /* background-image: url("../assets/images/about/mv.png"); */
}

.idea {
  width: 100%;
  min-height: 288px;
  margin: auto;
  margin-top: 30px;

  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url("../assets/images/about/bg_about.png");
}

.ideaContent {
  max-width: 1200px;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

.ideaFirst {
  width: 30%;
  margin-top: 76px;
  height: 136px;
  background: rgba(13, 25, 49, 0.5);
  text-align: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 0.3s linear;
  position: relative;
  top: 0;
  font-size: 16px;
}
.about_list {
  max-width: 1200px;
  min-height: 400px;
  margin: auto;
  margin-top: 50px;
}
.about_list p {
  font-size: 16px;
  line-height: 24px;
  color: #878a95;
  font-weight: 400;
  text-align: left;
}
.certificate {
  max-width: 1200px;
  margin: auto;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.bg_about {
  background-image: url("../assets/images/about/introduce_bg.png");
  width: 100%;
  background-size: cover;
}
.list_content {
  background-image: url("../assets/images/about/introduce_bg2.png");
  /* background-size: cover; */
  background-size: contain;
  height: 295px;
}
.list_content img {
  margin-top: 20px;
}
.list_content p {
  color: #343434;
  font-size: 12px;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 400;
}
.viedo >>> .vjs-poster {
  background-size: cover !important;
}
.viedo >>> .video-js:hover .vjs-big-play-button {
  background-color: #ea5413;
}
.viedo >>> .vjs-custom-skin > .video-js .vjs-big-play-button {
  width: 72px;
  background-color: rgba(0, 0, 0, 0.45);
  font-size: 3.5em;
  border-radius: 50%;
  height: 72px !important;
  line-height: 2em !important;
  margin-top: -1em !important;
}
.certificateContainer {
  width: 30%;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 1px 1px 6.44px 2.56px rgb(232 235 234 / 39%);
}

.dataPage {
  margin-top: 30px;
  margin-bottom: 30px;
}
.dataPage >>> .ivu-page-item-active {
  border-color: #ea5413;
}
.dataPage >>> .ivu-page-item-active a {
  color: #ea5413;
}
.dataPage >>> .ivu-page-item:hover {
  border-color: #ea5413;
  color: #ea5413;
}
.dataPage >>> .ivu-page-item a:hover {
  color: #ea5413 !important;
}
.aboutImg {
  width: 300px;
  height: 220px;
}
@media screen and (max-width: 768px) {
  .main {
    padding-top: 50px;
  }
  .main-header {
    height: 166px;
  }
  .title {
    font-size: 20px;
  }
  .about_list {
    margin-top: 20px;
    padding: 10px;
  }
  .about_list p {
    font-size: 14px;
  }
  .ideaContent {
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .ideaFirst {
    width: 80%;
    margin: auto;
    font-size: 14px;
    height: 100px;
  }
  .ideaFirst2 {
    margin-top: 10px;
  }
  .certificateContainer {
    width: 80%;
    margin: auto;
  }
  .aboutImg {
    width: 200px;
    height: 160px;
  }
}
</style>